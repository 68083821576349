import React from "react"
import * as PropTypes from "prop-types"
import { Link } from "gatsby"

import { Breadcrum, SEO, Pagination, PostHeader } from "../components/index"
import * as postStyles from '../components/posts/posts.module.scss'
import { postsType, pageLinkType } from '../types'

class PostsPageTemplate extends React.PureComponent {
  constructor(props) {
    super(props);
    const { 
      pageContext: {
        page,
        pages
      }
    } = props;
    const showIntro = page === 1;
    this.breadcrumPages = [
      {
        text: 'Posts',
        to: '/posts/',
        active: showIntro
      }
    ];
    if(!showIntro) {
      this.breadcrumPages.push(      {
        text: `Page ${page} of ${pages}`,
        active: true,
        to: `/posts/${page}/`
      })
    }
  }

  render () {
    const {
      pageContext: {
        next, prev,
        page, pages,
        nodes, total,
        limit
      }
    } = this.props;
    const showIntro = page === 1;
    const title = "Recent Posts";
    const pageName = showIntro ? `/posts/` : `/posts/${page}/`;
    return (
      <>
        <SEO
          title={showIntro ? title : `Page ${page} - ${title}`}
          description="A Magic: the Gathering fan site dedicated to MTG token cards. Find original blog posts, history, lore, stories and articles all about MTG token cards."
          keywords={['blog', 'posts', 'stories', 'history', 'news', 'articles']}
          pageUrl={pageName}
        />
        <Breadcrum pages={this.breadcrumPages} />
        <h1>Recent posts</h1>
        {
          nodes.map((post)=>{
            const { title, author, formattedDate, shortDescription, imgSrc, route, id, theme } = post.node;
            return (
              <article key={id} className={postStyles.article}>
                <Link to={route} className={postStyles.link}>
                  <PostHeader
                    imgSrc={imgSrc}
                    title={title}
                    author={author}
                    date={formattedDate}
                    theme={theme}
                  />
                  <p>{shortDescription}</p>
                  <span className={postStyles.linkText}>View full post &rarr;</span>
                </Link>
              </article>
            )
          })
        }
        <Pagination
          prev={prev} next={next}
          page={page} pages={pages}
          total={total} basePath="/posts"
          perPage={limit}
        />
      </>
    )
  }
}

PostsPageTemplate.propTypes = {
  pageContext: PropTypes.shape({
    nodes: PropTypes.arrayOf(postsType).isRequired,
    total: PropTypes.number.isRequired,
    ...pageLinkType,
  }).isRequired
}

export default PostsPageTemplate
